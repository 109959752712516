<template>
  <div class="Bcontainer">
    <ul>
      <li class="hava-img">
        <a href="/" class="title"
          >我国第一批“丁克”已经退休，没有孩子的晚年，真的很不一般</a
        >
        <div class="content">
          <a href="" class="warp-img unShow640">
            <img
              class="img-blur-done"
              src="@/assets/img/BBS/14323154-f63d91c4bfeba8f4.jpg"
              alt="120"
            />
          </a>
          <div class="content-tab">
            <p class="abstract yichu">
              文/谷月 图片/网络
              “丁克”一词最早来源于美国嬉皮士盛行的时代，进入我国是上个世纪80年代。丁克的名称来自英文Double
              Income No...
            </p>
            <div class="meta">
              <p @click="onclick" class="on">
                <i class="iconfont icon-pinglun"></i><span>12</span>
              </p>
              <p @click="onclick">
                <i class="iconfont icon-dianzan"></i><span>12</span>
              </p>
              <p @click="onclick">
                <i class="iconfont icon-cai"></i><span>12</span>
              </p>
            </div>
          </div>
        </div>
      </li>
      <li class="hava-img">
        <router-link to="/BBS/detail" class="title"
          >我国第一批“丁克”已经退休，没有孩子的晚年，真的很不一般</router-link
        >
        <div class="content">
          <a href="" class="warp-img unShow640">
            <img
              class="img-blur-done"
              src="@/assets/img/BBS/14323154-f63d91c4bfeba8f4.jpg"
              alt="120"
            />
          </a>
          <div class="content-tab">
            <p class="abstract yichu">
              文/谷月 图片/网络
              “丁克”一词最早来源于美国嬉皮士盛行的时代，进入我国是上个世纪80年代。丁克的名称来自英文Double
              Income No...
            </p>
            <mate-box></mate-box>

          </div>
          
        </div>
      </li>
    </ul> <new-pagination></new-pagination>
  </div>
</template>

<script>
// import {apiAddress} from '../../api/BBS'
import mateBox from '../CommonConponent/BBSMeta/meta.vue'
import NewPagination from '../CommonConponent/newPagination.vue';
export default {
  name: "BBSContainer",
  components:{
    mateBox,
    NewPagination
  },
  data() {
    return {
      detail:'',
    };
  },

  mounted() {},

  methods: {
    onclick(e) {
      let on = e.currentTarget.classList;
      on.value.indexOf("on") != -1 ? on.remove("on") : on.add("on");
      // console.log(apiAddress().then(res=>{
      //   this.detail = res.result
      //   console.log(res.result);
      // }));
    },
  },
};  
</script>

<style lang="less" scoped>
.Bcontainer {
  max-width: 940px;
  padding:0 20px;
  height: 100%;
  background: white;
  //   a:visited {
  //     color: gray;
  //   }
  ul {
    li {
      width: 100%;
      margin: 0 0 15px;
      padding: 15px 2px 20px 0;
      border-bottom: 1px solid #f0f0f0;
      word-wrap: break-word;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .hava-img {
      // min-height: 112px;
      .content {
        display: flex;
        .content-tab {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .title {
        margin: 0 0 4px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.5;
      }
      .abstract {
        margin: 0 0 8px;
        font-size: 14px;
        line-height: 24px;
        color: #999;
        padding-right: 15px;
      }
      .meta {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        p {
          color: #555666;
          margin-right: 20px;
          display: flex;
          font-size: 14px;
          cursor: pointer;
          i {
            padding-right: 5px;
          }
        }
        .on {
          color: #0068b7;
        }
      }
      .warp-img {
        width: 125px;
        height: 100px;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          border: 1px solid #f0f0f0;
        }
      }
    }
  }
}
</style>