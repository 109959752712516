<template>
    <!-- 分页组件的封装 -->
    <div class="pagination">
        <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :page-sizes="[1,5,10,15,,20]"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="pageNum"
        :page-size="pageSize"
        :total="total"
        :small="small">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "Paging",
    data () {
        return {
            pageNum: 1 //当前页数
        }
    },
    //子组件接收父组件的值
    props:{
        'total': {   //总条目数
            required: false,//是否必须
            default: 0 //默认值
        },
        'pageSize': {
            required: false, //是否必须
            default: 10 //默认值（默认显示10条）
        },
        'small': {  //是否使用小型分页样式
            required: false, //是否必须
            type: Boolean,  //类型
            default: false  //默认值
        }
    },
    //计算属性  计算属性是基于他们的依赖做缓存 
    // （如果计算的逻辑大的话，可以用这个，比方说你先循环100次，再循环1000次，他会把上一次的循环也加上去，也就是缓存）
    computed: {
        
    },
    //侦听器 响应数据的变化
    watch: {
        //监听页数变化
        pageNum(val,index) {
            console.log(val); //值
            console.log(index);//下标
            // 改变这个值并不会触发 handleCurrentChange
            if (typeof val === "number") {
                this.pageNum = val;
            }
        },
    },
    methods: {
      // 当前页变化
      sizeChange(val) {
        // console.log(val);
        //子组件向父组件传值
        this.$emit("sizeChange", val);
      },
      // size变化
      currentChange(val) {
        // console.log(val);
        //子组件向父组件传值
        this.$emit('currentChange', val);
      },
    }
}
</script>

<style lang="less" scoped>
.pagination {
    margin: 20px 0;
    // text-align: right;
    .el-pagination{
        display: flex;
        justify-content:center;
    }
  }
</style>