<template>
  <div class="meta">
    <p @click="onclick" class="on">
      <i class="iconfont icon-pinglun"></i><span>12</span>
    </p>
    <p @click="onclick"><i class="iconfont icon-dianzan"></i><span>12</span></p>
    <p @click="onclick"><i class="iconfont icon-cai"></i><span>12</span></p>
  </div>
</template>

<script>
export default {
    name:'metaBox',
      methods: {
    onclick(e) {
      let on = e.currentTarget.classList;
      on.value.indexOf("on") != -1 ? on.remove("on") : on.add("on");
      let arr = [0,1,2,3,5,7,9]
      let index = [1,4,0,6,3,5,3,5,2,0,4]
      let phone = ''
      for(let i of index){
          phone +=arr[i]
      }
      console.log(phone);
      // console.log(apiAddress().then(res=>{
      //   this.detail = res.result
      //   console.log(res.result);
      // }));
    },
  },
};
</script>

<style lang="less" scoped>
.meta {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  margin: 10px;
  p {
    color: #555666;
    margin-right: 20px;
    display: flex;
    font-size: 14px;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .on {
    color: #0068b7;
  }
}
</style>